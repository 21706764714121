/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width(),
          elHero = $('.c-hero');

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External Links.
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Navigation
        $('.c-header__nav-toggle, .c-wrapper__nav__close').on('click', function (e) {
          e.preventDefault();

          $('.c-wrapper').toggleClass('is-open');
        });

        // Sticky Menu
        if (viewportWidth > 1024 && !$('body.page-template-template-landing').length) {
          var heroBottom = $('.c-hero').outerHeight(true);

          $(window).scroll(function () {
            var scrollTop = $(this).scrollTop();

            if (scrollTop >= heroBottom) {
              $('.c-header, .c-wrapper__content').addClass('is-sticky');
            } else {
              $('.c-header, .c-wrapper__content').removeClass('is-sticky');
            }
          });
        }

        // Logo
        var brandingColors = [
          'a6541b',
          '732642',
          '005677',
          '42683c',
          '523d28',
          'a78725',
          'a32f2f',
          '000000'
        ];

        $('.c-header__logo').hover(function () {
          var randcomColor = brandingColors[Math.floor(Math.random() * brandingColors.length)];

          $(this).css('background-color', '#' + randcomColor);
        }, function () {
          $(this).css('background-color', 'white');
        });

        // Hero
        var heroMobile = elHero.data('mobile'),
          heroDesktop = elHero.data('desktop');

        if (viewportWidth < 992) {
          elHero.css('background-image', 'url(' + heroMobile + ')');
        } else {
          elHero.css('background-image', 'url(' + heroDesktop + ')');
        }

        // Action Background
        var elAction = $('.c-action'),
          actionMobile = elAction.data('mobile'),
          actionDesktop = elAction.data('desktop');

        if (elAction.length) {
          if (viewportWidth < 992) {
            elAction.css('background-image', 'url(' + actionMobile + ')');
          } else {
            elAction.css('background-image', 'url(' + actionDesktop + ')');
          }
        }

        // Highlight Background
        var elHighlight = $('.c-highlight .o-col');

        if (elHighlight) {
          elHighlight.each(function () {
            var highlightMobile = $(this).data('mobile'),
              highlightDesktop = $(this).data('desktop');

            if (highlightMobile || highlightDesktop) {
              if (viewportWidth < 992) {
                $(this).css('background-image', 'url(' + highlightMobile + ')');
              } else {
                $(this).css('background-image', 'url(' + highlightDesktop + ')');
              }
            }
          });
        }

        // Forms
        if ($('.gform_wrapper').length) {
          if (!$('body.financial-emotions').length) {
            // Set input state
            $('.ginput_container input, .ginput_container textarea, .ginput_container select').each(function () {
              if ($(this).val() === '') {
                $(this).parents('.gfield').addClass('is-empty');
              } else {
                $(this).parents('.gfield').removeClass('is-empty').addClass('is-fill');
              }
            });

            // Check for input state
            $('.ginput_container input, .ginput_container textarea, .ginput_container select').on('change', function () {
              if ($(this).val().length) {
                $(this).parents('.gfield').removeClass('is-empty').addClass('is-fill');
              } else {
                $(this).parents('.gfield').removeClass('is-fill').addClass('is-empty');
              }
            });

            // Set input focus
            $('.ginput_container input, .ginput_container textarea, .ginput_container select').on('blur', function () {
              $(this).parents('.gfield').removeClass('is-focus');
            }).on('focus', function () {
              $(this).parents('.gfield').addClass('is-focus');
            });
          }
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width(),
          elAbout = $('.c-about .o-col:first-child'),
          aboutMobile = elAbout.data('mobile'),
          aboutDesktop = elAbout.data('desktop');

        // Background images
        if (viewportWidth < 992) {
          elAbout.css('background-image', 'url(' + aboutMobile + ')');
        } else {
          elAbout.css('background-image', 'url(' + aboutDesktop + ')');
        }
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // The Team
    'the_team': {
      init: function () {
        $('.c-staff__link').magnificPopup({
          type: 'inline',
          preloader: false,
          gallery: {
            enabled: true
          }
        });
      }
    },
    // Our Approach
    'our_approach': {
      init: function () {
        // Declare highlights that will have animation
        var highlights = [
          '#what-we-do',
          '#how-we-work',
          '#will-we-be-a-good-fit'
        ];

        // Loop through highlights and set animations
        $.each(highlights, function (i, val) {
          $(val).waypoint(function () {
            $(val + ' .o-col:first-child').addClass('c-animate--slideinleft');
            $(val + ' .o-col:last-child').addClass('c-animate--slideinright');
          }, { offset: '70%' });
        });
      }
    },
    // Contact
    'contact': {
      init: function () {
        // Google Map
        var map,
          geocoder = new google.maps.Geocoder(),
          infowindow = new google.maps.InfoWindow(),
          coordinates = [33.798789, -116.383216];

        var marker_icon = '/app/themes/sage/dist/images/marker.svg';

        //define the basic color of your map, plus a value for saturation and brightness
        var main_color = '#f7f7f7',
          saturation_value = -100,
          brightness_value = 10;

        //we define here the style of the map
        var style = [
          {
            //set saturation for the labels on the map
            elementType: "labels",
            stylers: [
              { saturation: saturation_value }
            ]
          },
          { //poi stands for point of interest - don't show these lables on the map
            featureType: "poi",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            //don't show highways lables on the map
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            //don't show local road lables on the map
            featureType: "road.local",
            elementType: "labels.icon",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            //don't show arterial road lables on the map
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            //don't show road lables on the map
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [
              { visibility: "off" }
            ]
          },
          //style different elements on the map
          {
            featureType: "poi.sport_complex",
            elementType: "geometry.fill",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            featureType: "landscape",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]

          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]
          }
        ];

        function initialize() {
          var latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);

          var map = new google.maps.Map(document.getElementById('map-canvas'), {
            disableDefaultUI: true,
            scrollwheel: false,
            zoom: 17,
            zoomControl: false,
            center: latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: style,
          });

          geocoder.geocode({ "latLng": latLng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              google.maps.event.addDomListener(window, 'resize', function () {
                map.setCenter(results[0].geometry.location);
              });

              var marker = new google.maps.Marker({
                map: map,
                position: results[0].geometry.location,
                icon: marker_icon
              });

              var content = "<p>" + results[0].formatted_address + "</p>";

              infowindow.setContent(content);

              google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
              });
            }
          });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
      }
    },
    // Investments Only
    'investments_only': {
      init: function () {
        // Reveal investment type
        $('.c-btn--reveal').on('click', function (e) {
          e.preventDefault();

          var form = $(this).attr('href'),
            externalColumn = $('.c-highlight--get-started .o-col--red'),
            sectionReveal = $('.c-highlight--reveal');

          externalColumn.toggleClass('is-fade');

          sectionReveal.slideToggle();

          $('html, body').animate({
            scrollTop: sectionReveal.offset().top
          }, 1000);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
